<template>
  <div>
    <v-row no-gutters align="center">
      <v-col cols="10">
        <v-row align="center" justify="space-between" class="d-flex">
          <v-col class="" cols="12" md="2" xs="4">
            <span class="">{{ $t("message.label-order-id") }}</span>
            <v-text-field class="mt-1" type="text" outlined></v-text-field>
          </v-col>
          <v-col class="" cols="12" md="2" xs="4">
            <span class="">{{ $t("message.label-name") }}</span>
            <v-text-field class="mt-1" type="text" outlined></v-text-field>
          </v-col>
          <v-col class="" cols="12" md="2" xs="4">
            <span class="">{{ $t("message.label-postal") }}</span>
            <v-text-field class="mt-1" type="text" outlined></v-text-field>
          </v-col>
          <v-col class="" cols="6" md="4" xs="4">
            <span class="">{{ $t("message.label-address") }}</span>
            <v-text-field class="mt-1" type="text" outlined></v-text-field>
          </v-col>

          <v-col class="" cols="6" md="2" sm="6">
            <span class="">{{ $t("message.label-integrationId") }}</span>
            <v-text-field class="mt-1" type="text" outlined></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-btn
          rounded
          color="primary"
          class="btn-search-filter-customer"
          style="float: right"
        >
          <v-icon left>
            {{ iconSearch }}
          </v-icon>
          {{ $t("message.btn-search") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
export default {
  name: "FilterLocker",
  data() {
    return {
      iconSearch: mdiMagnify,
    };
  },
};
</script>

<style>
.btn-search {
  border-radius: 20px;
}
.v-text-field .v-input__control .v-input__slot {
  /* min-height: auto !important; */
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px;
  align-self: center !important;
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.col-filter-input {
  padding-right: 10px !important;
  padding-left: 0px !important;
}
</style>
