<template>
  <v-container>

    <div class="d-flex flex-row justify-between align-center">
      <h3>{{ $t("message.title-locker") }}</h3>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>

      <!-- routering to create item --> 
      <template>
        <router-link to="/addNewLocker">
          <v-btn rounded color="primary" class="btn-toolbar btn-toolbar-mobile">
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $t("message.btn-add") }}
          </v-btn>
        </router-link>
      </template>
    </div>

    <FilterLocker />
    <template v-slot:top>
      <v-toolbar flat>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">{{
              $t("message.confirm-delete")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">{{
                $t("message.btn-cancel")
              }}</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
                $t("message.btn-ok")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <!-- <template>
      <div class="text-start" style="padding-bottom: 10px;"> 
        <v-pagination
          color="indigo"
          v-model="page"
          :length="15"
          :total-visible="7"
        ></v-pagination>
      </div>
    </template>
    <table class="table table-responsive">
      <thead>
        <tr>
          <th
            colspan="1"
            rowspan="2"
            scope="colgroup"
            class="align-text-center"
          >
            ID
          </th>
          <th
            colspan="1"
            rowspan="2"
            scope="colgroup"
            class="align-text-center"
          >
            Name
          </th>
          <th
            colspan="1"
            rowspan="2"
            scope="colgroup"
            class="align-text-center"
          >
            Address
          </th>
          <th
            colspan="3"
            rowspan="1"
            scope="colgroup"
            class="align-text-center"
            style="border-bottom:transparent;"
          >
            Capacity
          </th>
          <th
            colspan="1"
            rowspan="2"
            scope="colgroup"
            class="align-text-center"
          >
            integrationId
          </th>
          <th
            colspan="1"
            rowspan="2"
            scope="colgroup"
            class="align-text-center"
          >
            Action
          </th>
        </tr>
        <tr>
          <th class="align-text-center">small</th>
          <th class="align-text-center">Medium</th>
          <th class="align-text-center">Hard</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in lockerData" :key="idx">
          <td class="align-text-center">{{ item.id }}</td>
          <td class="align-text-center">{{ item.name }}</td>
          <td class="align-text-center">{{ item.address }}</td>
          <td class="align-text-center">{{ item.capacity.children.small }}</td>
          <td class="align-text-center">{{ item.capacity.children.medium }}</td>
          <td class="align-text-center">{{ item.capacity.children.hard }}</td>
          <td class="align-text-center">{{ item.integrationId }}</td>
          <td class="align-text-center">
            <v-icon small class="mr-2" color="indigo">
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" color="secondary">
              mdi-eye
            </v-icon>
            <v-icon small color="red" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </tbody>
    </table>
    <template>
      <div class="text-start"> 
        <v-pagination
          color="indigo"
          v-model="page"
          :length="15"
          :total-visible="7"
        ></v-pagination>
      </div>
    </template> -->
    <v-data-table
      :headers="headers"
      :items="lockerData"
      :items-per-page="5"
      hide-default-header
      class="elevation-0"
    >
      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr>
            <th
              class="text-start width-class"
              v-for="(header, idx) in props.headers"
              :key="idx"
              :rowspan="header.children ? 1 : 2"
              :colspan="header.children ? header.children.length : 1"
              style="border: 1px solid #7986CB;"
            >
              {{ header.text }}
            </th>
          </tr>
          <tr>
            <th
              class="text-start"
              v-for="(subheader, idx) in getSubHeader(props.headers)"
              :key="idx"
              style="border: 1px solid #7986CB;"
            >
              {{ subheader.text }}
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:[`item`]="{ item }">
        <tr>
          <td
            v-for="(children, childrenIndex) in getChildrenData(item)"
            :key="childrenIndex"
            class="text-td"
          >
            {{ children }}
          </td>
          <td class="text-center">
            <router-link to="/editItem" style="text-decoration: none;">
              <v-icon color="indigo" small class="mr-2">
                mdi-pencil
              </v-icon>
            </router-link>
            <v-icon color="indigo" small class="mr-2">
              mdi-eye
            </v-icon>
            <v-icon color="red" small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </template>

      <template v-slot:top="{ pagination, options, updateOptions }">
        <!-- dialog delete confirmation -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">{{
              $t("message.confirm-delete")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">
                {{ $t("message.btn-cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                {{ $t("message.btn-ok") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          :items-per-page-text="$t('message.rows-per-page')"
          class="elavation-0 v-data-footer-top"
          :disable-items-per-page="true"
        />
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import FilterLocker from "@/components/FilterLocker";
export default {
  name: "ItemList",
  components: {
    FilterLocker,
  },
  data: () => ({
    page: 1,
    dialog: false,
    dialogDelete: false,
    lockerData: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("message.label-id"),
          align: "start",
          value: "id",
        },
        { text: this.$t("message.label-name"), 
          value: "name" ,
          // align: "center"
          },

        { text: this.$t("message.label-address"), value: "address" ,
          // align: "center"
        },
        {
          text: this.$t("message.label-capacity"),
          value: "capacity",
          divider: true,
          children: [
            { text: this.$t("message.label-small"), value: "small" },
            { text: this.$t("message.label-medium"), value: "medium" },
            { text: this.$t("message.label-big"), value: "hard" },
          ],
        },
        { text: this.$t("message.label-integrationId"), value: "integrationId" },
        { text: this.$t("message.label-action"), value: "actions" },
      ];
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.lockerData = [
        {
          id: 1,
          name: "Your Shop Name",
          address: "Jln Mawar, Beji, Depok",
          integrationId: "123xxx",
          capacity: {
            children: { small: "1", medium: "2", hard: "3" },
          },
        },
        {
          id: 2,
          name: "Your Shop Name",
          address: "Jln Mawar, Beji, Depok",
          integrationId: "456xxx",
          capacity: {
            children: { small: "1", medium: "2", hard: "3" },
          },
        },
        {
          id: 3,
          name: "Your Shop Name",
          address: "Jln sehat, Tajimi, jepang",
          integrationId: "888xxx",
          capacity: {
            children: { small: "1", medium: "2", hard: "3" },
          },
        },
      ];
    },
    getSubHeader(headers) {
      let result = [];
      headers
        .filter((header) => header.children)
        .forEach((data) => {
          result = result.concat(data.children);
        });
      return result;
    },
    getChildrenData(params) {
      let result = [];
      for (let prop in params) {
        if (prop === "capacity") {
          let tempObj = params[prop].children;
          let toArray = Object.values(tempObj);
          for (let i = toArray.length - 1; i >= 0; i--) {
            result.splice(3, 0, toArray[i]);
          }
        } else {
          result.push(params[prop]);
        }
      }
      return result;
    },
    editItem(item) {
      this.editedIndex = this.lockerData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.lockerData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.lockerData.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.lockerData[this.editedIndex], this.editedItem);
      } else {
        this.lockerData.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
<style>
.table > tbody > tr > td {
  vertical-align: middle;
}
/* .align-text-center {
  text-align: start !important;
  vertical-align: middle !important;
} */
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}
thead tr th {
  color: white !important;
  font-size: 15px !important;
}
tbody tr:hover:not(.v-table__expanded__content) {
  /* background: #c5cae9 !important; */
}
.v-data-footer-top {
  border-top: none !important;
}
.toolbar-locker-class .v-toolbar__content {
  padding-left: 0px;
}
.v-data-footer__select {
  margin-left: 0px !important;
}
.btn-toolbar {
  font-size: 12px !important;
}
.v-pagination {
  justify-content: start !important;
}
.v-application .text-start {
  text-align: center !important;
}
@media only screen and (max-width: 691px) {
  .toolbar-title-class {
    font-size: 20px !important;
  }
  .v-btn .v-btn__content {
    font-size: 11px !important;
  }
  .v-btn .v-btn__content i {
    font-size: 13px !important;
  }
  .btn-toolbar-mobile {
    width: 8em !important;
    padding-left: 10px !important;
  }
}
</style>
